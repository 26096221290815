// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav1 lists

@mixin nav1-divider($color: $nav1-divider-color, $margin-y: $nav1-divider-margin-y, $ignore-warning: false) {
  height: 0;
  margin: $margin-y 0;
  overflow: hidden;
  border-top: 1px solid $color;
  @include deprecate("The `nav1-divider()` mixin", "v4.4.0", "v5", $ignore-warning);
}
